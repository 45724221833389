<template>
  <div
    @scroll="isBottom"
    id="list-purchase"
  >
    <b-modal
      id="modal-response"
      v-model="modalVisible"
      hide-footer
      hide-body
      centered
      class="p-3"
    >
      <template #modal-title>
        <span class="h4 mb-0 text-dark">{{ $t('profile.billing.paymentsNeedCompletion') }}</span>
        <div>
          <label class="text-muted">{{ modalTitle || '-' }}</label>
        </div>
      </template>
      <!-- Example #1 -->
      <div
        class="d-flex flex-column"
        v-for="({title, total, url, listPayment, totalUnpaid, totalBills}, key) in listBillPayment"
        :key="key"
      >
        <div class="d-flex flex-column p-2 border-bottom">
          <div>
            <h5>{{title}}<span class="badge badge-pill ml-2" style="background-color: var(--biz-secondary-100); color: var(--biz-brand-base);">{{totalUnpaid}}</span></h5>
          </div>
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <h5 style="color: var(--biz-secondary-100);">{{total}}</h5>
              <div class="d-flex align-items-center">
                <span style="color: #767676">{{listPayment}}</span>
              </div>
            </div>
            <div class="d-flex align-items-end">
              <div
                @click="listPayment === 'Credit limit' ? (totalBills > 0 ? $router.push({ path: url}) : () => {}) : $router.push({ path: url})"
                :style="`border: solid 1px var(--biz-primary-100); color: var(--biz-primary-100); background-color: ${listPayment === 'Credit limit' ? (totalBills > 0 ? '' : '#e4e8f0') : ''};`"
                class="btn d-flex align-items-center justify-content-center rounded my-2 w-100"
              >
                <span class="h6 mb-0">{{ $t('profile.billing.viewDetails') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <h2 class="mb-5">{{ $t('profile.billing.billingTitle') }}</h2>
    <div class="d-flex col pr-0 justify-content-between mb-3">
      <div class="d-flex container-button-type gap-12 justify-content-between align-items-center mb-3">
        <div
          v-for="({ title }, key) in listTab"
          :key="key"
        >
          <div
            v-if="key === activeKey"
            class="btn active flex ml-1 w-auto"
            style="border-radius: 16px"
            @click="activeKey = key"
          >{{ title }}</div>
          <div
            v-else
            class="btn inactive flex ml-1 w-auto"
            style="border-radius: 16px"
            @click="activeKey = key"
          >{{
              title
          }}</div>
        </div>
      </div>
      <div
        @click="$router.push({ path: '/account/bill-history/'})"
        class="btn d-flex rounded align-self-end align-items-center justify-content-between mb-3 bill-history"
      >
        <Note :color="'var(--biz-primary-100)'"/>
        <span class="mx-2">Riwayat Transaksi Pembayaran Tempo</span>
        <span class="h4 mb-0">
          <i class="fe fe-chevron-right font-size-18" />
        </span>
      </div>
    </div>
    <template v-if="!loading && data.length === 0">
      <div class="d-flex pr-0 justify-content-center align-items-center flex-column pt-5">
        <div
          v-if="activeKey === 0"
          class="mt-3 ml-2 h4 align-items-center d-flex flex-column"
        >
          <div class="d-flex align-items-center mb-4">
            <EmptyIcon />
          </div>
          <span
            style="color: #015CA1"
            class="ml-2 desc-empty-state"
          >Anda belum memiliki transaksi. Yuk, mulai belanja</span>
          <div class="card-footer border-top-0 mt-1">
            <button
              @click="goToHome"
              class="btn w-100 button-empty-state"
            >Belanja
              Sekarang</button>
          </div>
        </div>
        <div
          v-else
          class="w-35 d-flex align-items-center flex-column pb-5"
        >
          <div class="d-flex align-items-center">
            <EmptyIcon />
          </div>
          <span
            style="color: #015CA1"
            class="ml-2 h4 mt-3 desc-empty-filter"
          >Tidak ada transaksi yang sesuai
            filter</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        id="wrap"
        class="col pr-0"
        v-for="({ name, fullName, isThereABill, totalBill, overDue, sellerId }, index) in data"
        :key="index"
      >
        <div class="card col-12 d-flex justify-content-center">
          <div class="card-header d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center justify-content-center">
              <pin-icon
                :width="15"
                :height="23"
                :color="'var(--biz-secondary-100)'"
              />
              <div class="d-flex flex-column ml-3">
                <span class="ml-2 h6">{{ name }}</span>
                <span class="ml-2 h5">{{ fullName }}</span>
              </div>
            </div>
            <div>
              <div
                style="border-radius: 16px"
                v-bind:style="{ backgroundColor: isThereABill ? 'var(--biz-secondary-100)' : 'var(--biz-primary-100)' }"
                class="px-3 py-2 d-flex align-items-center justify-content-center text-light"
              >
                <span
                  v-if="isThereABill"
                  class="h6 mb-0"
                >Terdapat Tagihan</span>
                <span
                  v-else
                  class="h6 mb-0"
                >Tidak Terdapat Tagihan</span>
              </div>
            </div>
          </div>
          <div class="card-body d-flex justify-content-between align-items-end">
            <div>
              Total Tagihan <span
                class="h5"
                style="color: var(--biz-secondary-100);"
              >{{ totalBill }}</span>
            </div>
          </div>
          <div class="card-footer border-0 d-flex justify-content-between align-items-center">
            <div
              style="width: 60%"
              :style="{ backgroundColor: overDue.length > 0 ? '#F4F4F4' : 'white' }"
              class="py-4 px-3 rounded"
            >
              <div
                class="text-danger d-flex align-items-center justify-content-around"
                v-if="overDue.length > 0"
              >
                <Information />
                <span class="ml-2">{{ `Terdapat ${overDue.length} tagihan pembayaran tempo
                telah melewati batas waktu maksimal pelunasan`}}</span>
              </div>
            </div>
            <button
              style="width: 100px; border: solid 1px var(--biz-primary-100); color: var(--biz-primary-100)"
              v-if="isThereABill"
              class="btn rounded d-flex align-items-center justify-content-around"
              @click="summaryCompletion(sellerId, fullName)"
            >
              <Wallet color="var(--biz-primary-100)" />
              <span>Bayar</span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PinIcon from '@/components/Icons/Pin.vue'
import Wallet from '@/components/Icons/Wallet.vue'
import Information from '@/components/Icons/Information.vue'
import Note from '@/components/Icons/Note.vue'
const LIST_PAYMENT_NAME = [
  {
    value: 'CAD',
    name: 'Pembayaran Tempo',
    listPayment: ['Credit limit'],
  },
  {
    value: 'COD',
    name: 'Melalui Kurir',
    listPayment: ['Cash On Delivery'],
  },
  {
    value: 'CBD',
    name: 'Cash Before Delivery',
    listPayment: ['Virtual Account', 'E-Wallet', 'Bank Transfer'],
  },
]
export default {
  components: {
    PinIcon,
    Wallet,
    Information,
    Note,
  },
  name: 'Bill',
  data() {
    return {
      loading: false,
      isPayModal: false,
      modalVisible: false,
      modalTitle: '',
      getSummaryCompletion: {},
      getBillingContent: {},
      statusPaid: {
        UNPAID: true,
        PAID: false,
      },
      listBillPayment: [],
      data: [],
    }
  },
  methods: {
    async summaryCompletion(sellerId, title) {
      const getSummaryCompletions = await this.$store.dispatch(
        'billing/GETBILLINGSUMMARY',
        {
          channel_id: this.$store.state.user.user_data.channel_id,
          customer_id: this.$store.state.user.user_data.buyer_id,
          seller_id: sellerId,
        },
      )
      this.getSummaryCompletion = getSummaryCompletions.data
      const loopTerm = []

      for (
        let index = 0;
        index < getSummaryCompletions.data.list_summary.length;
        index++
      ) {
        const element = getSummaryCompletions.data.list_summary[index]
        const selectedPayment = LIST_PAYMENT_NAME.find((obj) => obj.value === element.paymentTerm)
        loopTerm.push({
          title: selectedPayment ? selectedPayment.name : '-',
          listPayment: selectedPayment ? selectedPayment.listPayment.join(', ') : [],
          total: Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
          }).format(element.totalBills),
          totalBills: element.totalBills,
          totalUnpaid: element.totalUnpaidBills,
          url: element.paymentTerm.toUpperCase() === 'CAD' ? `/account/bill-payment/${sellerId}` : element.paymentTerm.toUpperCase() === 'CBD' ? '/purchase/cash-delivery' : '/purchase/courier',
          term: element.paymentTerm,
        })
      }
      this.listBillPayment = loopTerm
      this.modalTitle = title
      this.modalVisible = true
    },
    async billingContent() {
      const getBillingContents = await this.$store.dispatch(
        'billing/GETBILLSCONTENTS',
        {
          channel_id: this.$store.state.user.user_data.channel_id,
          customer_id: this.$store.state.user.user_data.buyer_id,
        },
      )
      const listSellerId = this.$store.state.user.user_data.seller.map((item) => item.id)
      if (listSellerId) {
        const promises = []
        listSellerId.forEach((sellerId) => {
          promises.push(
            this.$store.dispatch('user/GETDETAILDISTRIBUTOR', {
              sellerId: sellerId,
            }),
          )
        })
        Promise.all(promises)
          .then((data) => {
            const sellerData = []
            data.forEach((obj) => {
              if (obj.data) {
                sellerData.push(obj.data)
              }
            })
            const loopBills = []
            this.getBillingContent = getBillingContents.data
            getBillingContents.data.contents.forEach((element) => {
              const sellerIdDetail = sellerData.find((obj) => obj.id === element.seller_id)
              loopBills.push({
                name: 'Distributor',
                fullName: sellerIdDetail ? sellerIdDetail.name : '-',
                isThereABill: this.statusPaid[element.status],
                totalBill: Intl.NumberFormat('id-ID', {
                  style: 'currency',
                  currency: 'IDR',
                }).format(element.total_bills),
                overDue: [],
                sellerId: element.seller_id,
              })
            })
            this.data = loopBills
          })
          .catch((err) => {
            this.$notification.error({
              message: err.response.statusText,
            })
          })
      }
    },
    isBottom() {
      // const el = document.querySelectorAll('#wrap')
      // const navbarHeight = 151
      // const offsetElement = 5
      // const lastEl = el[el.length - offsetElement]
      // const bounds = lastEl.getBoundingClientRect().y
      // const alreadyGetAllData = this.data.length >= this.total
      // if (!this.loading && !alreadyGetAllData && bounds < navbarHeight) {
      //   const getValueByKey = this.listTab.find(({ key }) => key === this.activeKey)
      //   this.fetchPurchase(getValueByKey?.value, true)
      // }
    },
    handleIsPayModal(payload) {
      this.isPayModal = payload
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.isBottom)
  },
  mounted: function () {
    this.billingContent()
    window.addEventListener('scroll', this.isBottom)
  },
}
</script>

<style lang="scss" scoped>
.title-modal-detail-payment {
  font-size: 18px;
  color: #015ca1;
}
.subtitle-modal-detail-payment {
  font-size: 14px;
  color: #000000;
  margin-bottom: 25px;
}
.section-detail-payment {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;

  .section-detail-payment-detail {
    .detail-title {
      color: #015ca1;
      font-size: 14px;

      span {
        background: #ff8300;
        padding: 2px 6px;
        border-radius: 50%;
        color: white;
      }
    }
    .detail-price {
      color: var(--biz-secondary-100);
      font-size: 16px;
    }
    .detail-subtitle {
      color: #767676;
      font-size: 12px;
    }
  }
  .section-detail-payment-action {
    margin: auto 0;
  }
}
.bill-history {
  background-color: var(--biz-brand-base);
  border: solid 1px var(--biz-primary-100);
  color: var(--biz-primary-100) !important;
  &:hover {
    background-color: var(--biz-primary-100);
    color: var(--biz-brand-base) !important;
    border: solid 1px var(--biz-brand-base);
  }
}
</style>
